import axios from "axios";
import { useRef, useState } from "react";
import { APPOINTMENT_FORM, AppointmentFormData, MobileProps } from "../domain";
import { appointmentApiConfig, appointmentUrl } from "../utils";
import { EkAccountantsServiceList } from "../data/BookkeepingServices";

export const Appointment: React.FunctionComponent<MobileProps> = ({ isMobile }) => {
    const appointmentForm = useRef(null);
    const [loading, setLoader] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [category, setCategory] = useState("");
    const [message, setMessage] = useState("");

    const submitAppointmentForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoader(true);
        const data: AppointmentFormData = {
            [APPOINTMENT_FORM.NAME]: name,
            [APPOINTMENT_FORM.PHONE]: phone,
            [APPOINTMENT_FORM.EMAIL]: email,
            [APPOINTMENT_FORM.CATEGORY]: category,
            [APPOINTMENT_FORM.MESSAGE]: message,
            [APPOINTMENT_FORM.CREATED_TIMESTAMP]: new Date().toString(),
        };
        // @ts-ignore
        appointmentForm.current.reset();
        sendAppointmentRequest(data);
    };

    const resetAppointmentForm = async () => {
        console.log('resetting form ->>>>', appointmentForm.current);
        // @ts-ignore
        appointmentForm.current.reset();
    };

    const sendAppointmentRequest = async (data: AppointmentFormData) => {
        try {
            await axios.post(appointmentUrl, data, appointmentApiConfig);
        } catch (error) {
        } finally {
            setLoader(false);
            resetAppointmentForm();
        }
    };

    return (
        <>
            <section id="consultation" className="section cta pt-0 pb-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-content pl-100">
                            <div className="heading heading-1 wow fadeInUp" data-wow-duration="1s">
                                <h2 className="heading--title">Free Consultation</h2>
                                <p className="heading--desc">
                                    Please provide details regarding your query and our team will get in touch with you.
                                </p>
                                <div className="heading--divider mb-80"></div>
                            </div>
                            <div className="form-request">
                                <div className="row">
                                    <form ref={appointmentForm} className="mb-0" onSubmit={submitAppointmentForm}>
                                        <input type="hidden" name="dlut" value="1712892095928" />
                                        <div className="col-xs-12 col-sm-12 col-md-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="your-name"
                                                placeholder="Your Name"
                                                onChange={(e) => setName(e.target.value)}
                                                disabled={loading ? true : false}
                                            />
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6">
                                            <input
                                                type="email"
                                                className="form-control text--none"
                                                name="your-email"
                                                placeholder="Your Email"
                                                disabled={loading ? true : false}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6">
                                            <input
                                                type="text"
                                                className="form-control text--none"
                                                name="your-phone"
                                                placeholder="Your Phone"
                                                disabled={loading ? true : false}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6">
                                            <select
                                                className="form-control"
                                                onChange={(event) => setCategory(event.target.value)}
                                                disabled={loading ? true : false}
                                            >
                                                <option value={""}>Service</option>
                                                {EkAccountantsServiceList.map((service, index) => {
                                                    return (
                                                        <option
                                                            key={`${index}-appointment-${service}`}
                                                            value={service}
                                                        >
                                                            {service}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <textarea
                                                className="form-control text--none"
                                                name="your-message"
                                                rows={3}
                                                placeholder="Your Message"
                                                required={true}
                                                onChange={(e) => setMessage(e.target.value)}
                                                disabled={loading ? true : false}
                                            ></textarea>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6">
                                            <input
                                                type="submit"
                                                className="btn btn--primary"
                                                value="send request"
                                                disabled={loading ? true : false}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {!isMobile && <div className="col-xs-12 col-sm-12 col-md-5 pr-0 pl-0">
                            <div className="col-img bg-parallex bg-section" style={{
                                backgroundImage: 'url(assets/images/background/bg-2.jpg)'
                            }}>
                            </div>
                        </div>}
                    </div>
                </div>
            </section>
        </>
    );
};
