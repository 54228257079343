import { MobileProps } from "../domain";

export const Hero: React.FunctionComponent<MobileProps> = ({ isMobile }) => {
	return (
		<section id="home" className="section slider">
			<div className="slide--item bg-overlay bg-overlay-dark bg-parallex bg-section" style={
				{
					backgroundImage: 'url(assets/images/slider/slide-1.jpg)',
				}
			}>
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-12 text-center wow fadeInUp" data-wow-duration="1s">
							<div className="slide--headline">Welcome to EK Accountants</div>
						</div>
						<div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-8 col-md-offset-2 text-center wow fadeInUp"
							data-wow-duration="1s">
							<div className="slide--bio">
								At Ek accountants, our mission is to empower businesses by providing accurate, timely, and strategic financial solutions. We aim to be trusted advisors, contributing to the growth and success of our clients.
							</div>
						</div>
						<div className="col-xs-12 col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3 text-center wow fadeInUp"
							data-wow-duration="1s">
							<div className="slide--action">
								<a className="btn btn--primary btn--bordered" href="#consultation">Free Consultation</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

	);
};
