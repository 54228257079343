import { AboutUs } from "../compounds/AboutUs";
import { Appointment } from "../compounds/Appointment";
import { Footer } from "../compounds/Footer";
import { Hero } from "../compounds/Hero";
import { Navbar } from "../compounds/Navbar";
import { Reviews } from "../compounds/Reviews";
import { Services } from "../compounds/Services";
import { Team } from "../compounds/Team";
import { WhyUs } from "../compounds/WhyUs";

const Root = () => {
  const isMobile = window.innerWidth <= 480;
  return (
    <>
      <Navbar />
      <Hero isMobile={isMobile} />
      <AboutUs />
      <Services isMobile={isMobile} />
      <Team isMobile={isMobile} />
      <WhyUs isMobile={isMobile} />
      <Reviews />
      <Appointment isMobile={isMobile} />
      <Footer />
    </>
  );
};

export default Root;
