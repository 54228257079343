export enum APPOINTMENT_FORM {
    NAME = 'entry.983289868',
    PHONE = 'entry.942499027',
    EMAIL = 'entry.1551397330',
    CATEGORY = 'entry.765658691',
    MESSAGE = 'entry.1155452941',
    CREATED_TIMESTAMP = 'entry.2011580862',
}

export interface AppointmentFormData {
    [APPOINTMENT_FORM.NAME]: string;
    [APPOINTMENT_FORM.PHONE]: string;
    [APPOINTMENT_FORM.EMAIL]: string;
    [APPOINTMENT_FORM.CATEGORY]: string;
    [APPOINTMENT_FORM.MESSAGE]: string;
    [APPOINTMENT_FORM.CREATED_TIMESTAMP]: string;
}
