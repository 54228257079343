import { MobileProps } from "../domain";

export const Team: React.FunctionComponent<MobileProps> = (isMobile) => {
  return (
    <>
      <section id="team" className="team team-1">
        <div className="container">
          <div className="row clearfix">
            <div className="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
              <div className="heading heading-1 text--center wow fadeInUp" data-wow-duration="1s">
                <h2 className="heading--title">Our Team</h2>
                <p className="heading--desc"></p>
                <div className="heading--divider mb-80 heading--center"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-4 col-md-4">
              <div className="member">
                <div className="member-img">
                  <img src="assets/images/team/t1.jpeg" alt="member" />
                  <div className="member-overlay">
                    <div className="member-social">
                      <div className=" pos-vertical-center">
                        <a href="mailto:nav.dhillon@ekaccountants.com.au"><i className="fa fa-envelope"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="member-info">
                  <h5>Nav Dhillon</h5>
                  <h6>Managing Director</h6>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4">
              <div className="member">
                <div className="member-img">
                  <img src="assets/images/team/t2.jpeg" alt="member" />
                  <div className="member-overlay">
                    <div className="member-social">
                      <div className=" pos-vertical-center">
                        <a href="mailto:nav.dhillon@ekaccountants.com.au"><i className="fa fa-envelope"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="member-info">
                  <h5>Anshika Bhardwaj</h5>
                  <h6>Assistant Accountant</h6>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4">
              <div className="member">
                <div className="member-img">
                  <img src="assets/images/team/t3.jpeg" alt="member" />
                  <div className="member-overlay">
                    <div className="member-social">
                      <div className=" pos-vertical-center">
                        <a href="mailto:nav.dhillon@ekaccountants.com.au"><i className="fa fa-envelope"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="member-info">
                  <h5>Tania Khurana</h5>
                  <h6>Social Media Manager</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
