import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export const Reviews = () => {
    const owlCarouselResponsive = {
        0 : {
            items: 1
        },
        600: {
            items: 2
        },
        1000: {
            items: 3
        }
    }
    return <>
        <section id="reviews" className="testimonial">
            <div className="container">
                <div className="row clearfix">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
                        <div className="heading heading-1 text--center wow fadeInUp" data-wow-duration="1s">
                            <h2 className="heading--title">Clients Feedback</h2>
                            {/* <p className="heading--desc">Lorem ipsum dolor amet, consectetur adipisice elite sede eiusmod tempor
                                incidide labeore dolore magna.</p> */}
                            <div className="heading--divider mb-80 heading--center"></div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <OwlCarousel
                            className='carousel'
                            responsive={owlCarouselResponsive}
                            loop
                            autoplay
                            nav={false}
                            dots={false}
                            margin={30}
                            dotsSpeed={800}
                        >
                            <div className="testimonial-panel">
                                <div className="testimonial--meta-img">
                                    <img src="assets/images/testimonial/4.png" alt="Testimonial Author" />
                                </div>
                                <div className="testimonial--body">
                                    <p>“Exceptional service! Our company has been utilizing their accounting and bookkeeping 
                                        services for over a year now, and we couldn't be happier. Their attention to detail 
                                        and professionalism have saved us both time and money. Highly recommend.”</p>
                                </div>
                                <div className="testimonial--meta">
                                    <h4><span>-</span> Chris</h4>
                                </div>
                            </div>
                            <div className="testimonial-panel">
                                <div className="testimonial--meta-img">
                                    <img src="assets/images/testimonial/4.png" alt="Testimonial Author" />
                                </div>
                                <div className="testimonial--body">
                                    <p>“I've worked with several accounting services in the past, but none have compared 
                                        to the expertise and personalized attention I've received here. They take the 
                                        time to understand my business's unique needs and have provided invaluable 
                                        advice that has helped us grow. Truly top-notch!”</p>
                                </div>
                                <div className="testimonial--meta">
                                    <h4><span>-</span> Connie</h4>
                                </div>
                            </div>
                            <div className="testimonial-panel">
                                <div className="testimonial--meta-img">
                                    <img src="assets/images/testimonial/4.png" alt="Testimonial Author" />
                                </div>
                                <div className="testimonial--body">
                                    <p>“I've struggled with keeping my books in order for years, but since partnering 
                                        with this accounting firm, that's no longer a concern. They're responsive, 
                                        knowledgeable, and have helped me understand my finances better than ever before. 
                                        Thank you for making my life easier.”</p>
                                </div>
                                <div className="testimonial--meta">
                                    <h4><span>-</span> Jatinder</h4>
                                </div>
                            </div>
                            <div className="testimonial-panel">
                                <div className="testimonial--meta-img">
                                    <img src="assets/images/testimonial/4.png" alt="Testimonial Author" />
                                </div>
                                <div className="testimonial--body">
                                    <p>“Efficient, reliable, and trustworthy are just a few words to describe the 
                                        experience I've had with this accounting firm. From day one, they've been 
                                        dedicated to helping my small business succeed. I appreciate their proactive 
                                        approach and highly recommend them to anyone in need of accounting and 
                                        bookkeeping services.”</p>
                                </div>
                                <div className="testimonial--meta">
                                    <h4><span>-</span> Angelina</h4>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    </>
}