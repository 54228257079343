import { MobileProps } from "../domain";

export const WhyUs: React.FunctionComponent<MobileProps> = ({isMobile}) => {
  return (
    <>
      <section id="why-us" className="bg-white pt-0 pb-0">
        <div className="container-fluid">
          <div className="row">
            {!isMobile && <div className="col-xs-12 col-sm-12 col-md-5 pr-0 pl-0">
              <div className="col-img  bg-overlay bg-parallex" style={
                {
                  backgroundImage: 'url(assets/images/background/bg-1.jpg)',
                }
              }>
              </div>
            </div>}
            <div className="col-xs-12 col-sm-12 col-md-7 col-content pl-100">
              <div className="row clearfix">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="heading heading-1 wow fadeInUp" data-wow-duration="1s">
                    <h2 className="heading--title">Why Hire Us ?</h2>
                    <p className="heading--desc">Hire us for your bookkeeping and accounting needs to benefit from our seasoned expertise, client-focused approach, and commitment to delivering accurate, transparent, and strategic financial solutions tailored to your business success.</p>
                    <div className="heading--divider mb-80"></div>
                  </div>
                </div>
              </div>
              <div className="row mb-50">
                <div className="col-xs-12 col-sm-6 col-md-6">
                  <div className="feature-panel">
                    <div className="feature--icon">
                      <i className="icon icon-expertise"></i>
                    </div>
                    <div className="feature--content">
                      <h3>Expertise & Experience</h3>
                      <p>Emphasize the qualifications and experience of your team. Highlight certifications, years of experience, and specific areas of expertise within bookkeeping such as tax preparation, payroll management, or financial analysis.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6">
                  <div className="feature-panel">
                    <div className="feature--icon">
                      <i className="icon icon-accuracy"></i>
                    </div>
                    <div className="feature--content">
                      <h3>Accuracy and Compliance</h3>
                      <p>Stress your commitment to accuracy and compliance with regulations. Highlight how your company stays updated with changing tax laws and accounting standards, ensuring that clients' financial records are accurate and compliant.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-6">
                  <div className="feature-panel">
                    <div className="feature--icon">
                      <i className="icon icon-cost-savings"></i>
                    </div>
                    <div className="feature--content">
                      <h3>Time and Cost Savings</h3>
                      <p>Illustrate how outsourcing bookkeeping services can save clients time and money. Emphasize the efficiency of your processes and how outsourcing frees up clients to focus on core business activities rather than spending time on bookkeeping tasks.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6">
                  <div className="feature-panel">
                    <div className="feature--icon">
                      <i className="icon icon-customised-solution"></i>
                    </div>
                    <div className="feature--content">
                      <h3>Customised Solutions</h3>
                      <p>Showcase your ability to tailor services to meet each client's unique needs. Whether it's managing finances for a small business, a non-profit organization, or an individual, emphasize how your company can provide personalized solutions.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
