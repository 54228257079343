export const AboutUs = () => {
  return (
    <>
      <section id="about" className="section about pb-0">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-5 wow fadeInUp">
              <img className="img-responsive center-block" src="assets/images/team/team-4.png" alt="ipad" />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-md-offset-1">
              <div className="heading heading-1 mt-70 wow fadeInUp" data-wow-duration="1s">
                <h2 className="heading--title">About Our Firm</h2>
                <div className="heading--divider"></div>
              </div>
              <div className="about-content wow fadeInUp" data-wow-duration="1s">
                <p>At Ek accountants, we are more than just a bookkeeping and accounting firm - We are your dedicated financial partners, committed to shaping your success through precision, insight, and unwavering support.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
