import React, { useEffect, useState } from "react";

export const Navbar: React.FunctionComponent = () => {
  const [scrollY, setScrollY] = useState(0);
  const [navSlider, setNavSlider] = useState('home');

  const updateScroll = () => {
    setScrollY(window.scrollY);
  }
  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
    return () => {
      window.removeEventListener("scroll", updateScroll);
    }
  }, []);
  const toggleNavMenu = () => {
    document.getElementById("navbar-button")?.click();
  }
  return (
    <>
      <header id="navbar-spy" className="header header-1 header-transparent header-fixed">
        <nav id="primary-menu" className={`navbar navbar-fixed-top ${scrollY === 0 ? 'affix-top' : 'affix'}`}>
          <div className="container">
            <div className="navbar-header">
              <button id="navbar-button" type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse-1" aria-expanded="false">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>

              <a className="logo" href="index-2.html">
                <img className="logo-dark" src="assets/images/logo/logo-dark.png" alt="appy Logo" />
                <img className="logo-light" src="assets/images/logo/logo-light.png" alt="appy Logo" />
              </a>
            </div>
            <div className="collapse navbar-collapse pull-right" id="navbar-collapse-1">
              <ul className="nav navbar-nav nav-pos-right navbar-left nav-split">
                <li className={navSlider === "home" ? "active" : ""}>
                  <a data-scroll="scrollTo" href="#home" onClick={() => {
                    toggleNavMenu();
                    setNavSlider("home");
                  }}>Home</a>
                </li>
                <li className={navSlider === "about" ? "active" : ""}>
                  <a data-scroll="scrollTo" href="#about" onClick={() => {
                    toggleNavMenu();
                    setNavSlider("about")
                  }}>About</a>
                </li>
                <li className={navSlider === "services" ? "active" : ""}>
                  <a data-scroll="scrollTo" href="#services" onClick={() => {
                    toggleNavMenu();
                    setNavSlider("services")
                  }}>Services</a>
                </li>
                <li className={navSlider === "team" ? "active" : ""}>
                  <a data-scroll="scrollTo" href="#team" onClick={() => {
                    toggleNavMenu();
                    setNavSlider("team")
                  }}>Team</a>
                </li>
                <li className={navSlider === "why-us" ? "active" : ""}>
                  <a data-scroll="scrollTo" href="#why-us" onClick={() => {
                    toggleNavMenu();
                    setNavSlider("why-us");
                  }}>Why Us</a>
                </li>
                <li className={navSlider === "reviews" ? "active" : ""}>
                  <a data-scroll="scrollTo" href="#reviews" onClick={() => {
                    toggleNavMenu();
                    setNavSlider("reviews")
                  }}>Reviews</a>
                </li>
                <li className={navSlider === "consultation" ? "active" : ""}>
                  <a data-scroll="scrollTo" href="#consultation" onClick={() => {
                    toggleNavMenu();
                    setNavSlider("consultation")
                  }}>Free Consultation</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
