import React from "react";
import { MobileProps } from "../domain";

export const Services: React.FunctionComponent<MobileProps> = ({ isMobile }) => {
  return (
    <>
      <section id="services" className="section feature feature-2 feature-left bg-white mb-60">
        <div className="container">
          <div className="row clearfix">
            <div className="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
              <div className="heading heading-1 text--center wow fadeInUp" data-wow-duration="1s">
                <h3 className="heading--title">Services Offered</h3>
                <p className="heading--desc">Best accounting services within your budget.</p>
                <div className="heading--divider mb-80 heading--center"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6">
              <div className="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div className="feature--icon">
                  <i className="icon icon-bas"></i>
                </div>
                <div className="feature--content">
                  <h3>BAS Lodgement</h3>
                  <p>Stay compliant effortlessly! Our Business Activity Statement (BAS) services ensure your business meets all obligations without breaking a sweat.</p>
                  <div className = "flex space-between">
                    <h3 className="pt-20 color-theme mb-1">Starting Price:</h3>
                    <h2 className="color-theme pr-sm">$89</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6">
              <div className="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div className="feature--icon">
                  <i className="icon icon-book-keeping"></i>
                </div>
                <div className="feature--content">
                  <h3>Bookkeeping Services</h3>
                  <p>Allow us to help you with your accounting needs! We ensure that your bookkeeping requirements are met in a timely manner.</p>
                  <div className = "flex space-between">
                    <h3 className="pt-20 color-theme mb-1">Starting Price:</h3>
                    <h2 className="color-theme pr-sm">$49</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6">
              <div className="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div className="feature--icon">
                  <i className="icon icon-company-tax"></i>
                </div>
                <div className="feature--content">
                  <h3>Company Tax Return</h3>
                  <p>Navigate the corporate tax landscape with ease! Our professionals ensure that your company's tax return is accurate, maximizing your returns and minimizing stress.</p>
                  <div className = "flex space-between">
                    <h3 className="pt-20 color-theme mb-1">Starting Price:</h3>
                    <h2 className="color-theme pr-sm">$199</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6">
              <div className="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div className="feature--icon">
                  <i className="icon icon-personal-tax"></i>
                </div>
                <div className="feature--content">
                  <h3>Personal Tax Return</h3>
                  <p>Your personal finances matter, and so do your tax returns! Let us handle the intricacies, so you can enjoy more of what you earn.</p>
                  <div className = "flex space-between">
                    <h3 className="pt-20 color-theme mb-1">Starting Price:</h3>
                    <h2 className="color-theme pr-sm">$89</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6">
              <div className="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div className="feature--icon">
                  <i className="icon icon-business-registration"></i>
                </div>
                <div className="feature--content">
                  <h3>Business Registration</h3>
                  <p>Our streamlined process ensures compliance with all legal requirements, leaving you free to focus on what you do best—providing exceptional services to your clients. Let us handle the paperwork while you build your business.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
