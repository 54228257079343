export const Footer = () => {
    return <>
        <footer id="footer" className="footer">
            <div className="footer-widget">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-4 footer--widget-about">
                            <div className="footer--widget-content">
                                <img className="mb-20" src="assets/images/logo/logo-light.png" alt="logo" />
                                <p>Empowering Your Finances, Enabling Your Future.</p>
                                <div className="clearfix"></div>
                                <div className="social">
                                    <a 
                                        className="share-instagram"
                                        href="https://www.instagram.com/ekaccountants_?igsh=dDU4M3E3bm16N3Jr&utm_source=qr"
                                        target="__blank"
                                    >
                                        <i className="fa fa-instagram"></i>
                                    </a>
                                    <a
                                        className="share-facebook"
                                        href="https://www.facebook.com/profile.php?id=61555443500925&mibextid=LQQJ4d"
                                        target="__blank"
                                    >
                                        <i className="fa fa-facebook"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 footer--widget-recent">
                            {/* <div className="footer--widget-title">
                                <h5>Blog Posts</h5>
                            </div>
                            <div className="footer--widget-content">
                                <div className="entry">
                                    <div className="entry--img">
                                        <img src="assets/images/blog/thumb/1.jpg" alt="entry" />
                                    </div>
                                    <div className="entry--content">
                                        <div className="entry--title">
                                            <a href="#">Four ways to cheer yourself up on the Blue Monday!</a>
                                        </div>
                                        <div className="entry--meta">
                                            <span>Jan 20, 2017</span>
                                            <span><i className="fa fa-comment-o"></i> 6 comments</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="entry">
                                    <div className="entry--img">
                                        <img src="assets/images/blog/thumb/2.jpg" alt="entry" />
                                    </div>
                                    <div className="entry--content">
                                        <div className="entry--title">
                                            <a href="#">Old cameras can capture images better than nowdays camera!</a>
                                        </div>
                                        <div className="entry--meta">
                                            <span>Jan 20, 2017</span>
                                            <span><i className="fa fa-comment-o"></i> 3 comments</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="entry">
                                    <div className="entry--img">
                                        <img src="assets/images/blog/thumb/3.jpg" alt="entry" />
                                    </div>
                                    <div className="entry--content">
                                        <div className="entry--title">
                                            <a href="#">Four ways to cheer yourself up on the Blue Monday!</a>
                                        </div>
                                        <div className="entry--meta">
                                            <span>Jan 20, 2017</span>
                                            <span><i className="fa fa-comment-o"></i> 5 comments</span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 footer--widget-contact">
                            <div className="footer--widget-title">
                                <h5>Get In Touch</h5>
                            </div>
                            <div className="footer--widget-content">
                                <ul className="list-unstyled mb-0">
                                    <li><i className="fa fa-map-marker"></i> Based In - Werribee, Australia.</li>
                                    <li><i className="fa fa-phone"></i> +61 470640487</li>
                                    <li><i className="fa fa-envelope-o"></i> nav.dhillon@ekaccountants.com.au</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer--copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 text--center">
                            <span>&copy; 2024, All rights reserved.</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <section id="credits" style={{display: "none"}}>
            <a href="https://www.flaticon.com/free-icons/activities" title="Activities icons">Activities icons created by orvipixel - Flaticon</a>
        </section>
    </>
}